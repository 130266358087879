.block-container-bg-image{
    width: 100%;
    height: 300px;
}

.block-container-bg-image .img-template-4{
    width: 100%;

}

.section-template4{
    height: 500px;
}