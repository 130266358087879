@use '/src/botones' as b;
@use '/src/variables'as v;


.card-title{

    font-family: Objectivity-Regular;
    margin-top: 20px;
    
}

#card-planes{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
border-radius: 10px;
height: 100%;

    
}

.card-body{
    border-radius: 20px;

}





.bg-planespage{
    background-image: url("../../assets/img/bg-pages-planes.png");
    background-position: center center;
    background-size: cover;
    height: 55vh;
}

.container-right-buttons{
    position: absolute;
    top: 0;
    right: 0;
    
}

.descripcion_plan{

    
    h2{
        font-family: Objectivity-Regular;
        font-weight: bold;
        font-size: 34px;
    }
    p{
        font-family: Objectivity-Regular;
        color: white;
        font-size: 19px;
    
    }
}

// .descripcion_plan{
//     position: absolute;
// }