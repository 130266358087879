@use './variables' as v;
@import "~bootstrap/scss/bootstrap";



@font-face {
  font-family: "Sanchez-Regular";
  src: url("../public/assets/font/Sanchez-Regular.ttf")
}

@font-face {
  font-family: "Objectivity-Regular";
  src: url("../public/assets/font/Objectivity-Regular.otf")
}

@font-face {
  font-family: "Objectivity-Bold";
  src: url("../public/assets/font/Objectivity-Bold.otf")
}

@font-face {
  font-family: "Objectivity-Medium";
  src: url("../public/assets/font/Objectivity-Medium.otf")
}

@font-face {
  font-family: "Objectivity-Light";
  src: url("../public/assets/font/Objectivity-Light.otf")
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}


.btn-floating:hover{
  transform: translateY(-20px);
  transition: 0.5s;
}

//Titulos


.global_title{
  font-size: 42px;
  color: v.$color2;
  font-family: Objectivity-Regular;
  font-weight: 500;
  // text-transform: capitalize;   
}

.global_subtitle{
  font-size: 35px;
  color: v.$color2;
  font-family: Objectivity-Regular;
  font-weight: 500;
}

.card-contenido{
  font-family: 'Sanchez-Regular';
  font-size: 22px;
}

.contenido{
  font-family: 'Objectivity-Regular';
  font-size: 18px;
  color: v.$color2;
}

hr{
  border-top: 0px solid !important;
}

// Ajustes Home

@media screen and (max-height:1079px)  {
  .hero
  {
    height: calc(100vh - 25px - 100px - 75px) !important;   
  }
  
  .nav-principal  > .container{
    height: 60px !important;
  }

  .nav-principal  > .container img{
    height: 55px !important;
  }

  .btn-botones-color1{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color2{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color3{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color4{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color5{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color6{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .btn-botones-color7{
    height: 70px !important;    
    line-height: 25px !important;
  }

  .drop-btn{
    height: 100% !important;
  }

  .dropdown{
    height: 100% !important; 
    background-color: white;   
  }

  .navbar-nav
  {
    height: 100% !important;
  }

  #basic-navbar-nav{
    height: 100% !important;
  }

  .dropbtn{    
    display: flex !important;
    align-items: center !important;
    height: 100% !important;    
  }

  .sin-submenu
  {
    background-color: white !important; 
  }

  .sin-submenu:hover
  {
    background-color: #06ACCD !important; 
  }

  .anchorWithoutSubmenuMain
  {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    min-width: inherit !important;  
  }

  .anchorWithoutSubmenu{
    font-size: 18px !important;
  }

}

.fontHeader{
    font-size: 15px;
}
  
// @media screen and (max-width:1024) and (max-height:768) {
//   .fontHeader{
//     font-size: 8px !important;
//   }  
// }

// FIN AJUSTES HOME
.whatsapp{
height: 20px;
}

@media (max-width:991px)
{
  body{
    padding-top: 80px;
  }

  

  .nav-desktop{
    display: none;
  }

  .nav-mobile{
    display: block;
  }
}

@media (min-width:992px)
{
  .nav-desktop{
    display: block;
  }

  .nav-mobile{
    display: none;
  }
}

@media (max-width: 992px) {
  .zsiq_flt_rel {
    bottom: 20px;
  }
}