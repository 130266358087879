.h1,
p {
  font-family: Objectivity-Regular;
  color: #004990;
  font-size: 18px;
}

.fondo {
  background-color: white;
  border-radius: 15px;
  box-shadow: 2px 3px 14px 2px rgba(0, 73, 144, 0.15);
  -webkit-box-shadow: 2px 3px 14px 2px rgba(0, 73, 144, 0.15);
  -moz-box-shadow: 2px 3px 14px 2px rgba(0, 73, 144, 0.15);
  margin: 30px 0 30px 0;
}

.icono_whatsapp {
  margin-right: 10px;
}

.logo_hb {
  width: 50px;
}

.titulos {
  font-weight: 700;
  font-size: 18px;
}

.borde {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.img-informacion {
  height: 100%;
  object-fit: cover;
}

.contenido-telefono.strong {
  font-size: 30px;
}

.icono_whatsapp {
  display: block;
}

.innerHTML {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 999px) {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
}

.card {
  border: 0px;
  height: 100%;
}

.card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;
}

@media screen and (max-width: 880px) {
  #centro-atencion {
    padding-top: -1em;
  }
}

@media screen and (max-width: 880px) {
  #odontologia {
    padding-top: 2rem;
  }
}
