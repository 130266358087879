.template-planes-contenido .contenido p::before {
  content: url("../../../assets/img/check-blue.png");
  margin: 0px 10px 0px 0px;
}

.planBox {
  margin-left: 40px;
  padding-bottom: 0 !important;
}

.container-description {
  border-bottom-left-radius: 120px;
  border-top-left-radius: 120px;
}

.descriptions-mobile {
  display: none;
}

.descriptions-desktop {
  display: desktop;
}

@media (max-width: 992px) {
  .global_title {
    font-size: 30px !important;
  }

  .row-options {
    margin-top: 1rem !important;
  }

  .bg-planespage {
    height: 175px;
    margin-top: 20px;
  }

  .descriptions-mobile {
    display: block;
    margin-top: -50px;
  }

  .descriptions-desktop {
    display: none;
  }

  .container-description {
    border-bottom-right-radius: 120px;
    border-top-right-radius: 120px;
    padding: 0px !important;
    margin: 0px 10px;
    max-width: 572px;
  }

  .col-text-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-text-buttons {
    // position: absolute;
    // top: 50vh;
  }

  .descripcion_plan {
    padding: 0px 15px !important;
  }

  .descripcion_plan h2 {
    font-size: 180% !important;
    text-align: center;
    padding-top: 15px;
  }

  .descripcion_plan p {
    font-size: 90% !important;
    text-align: center;
    padding-bottom: 10px;
  }

  .template-planes-contenido {
    //   margin-top:20vh;
  }

  .botones_container {
    justify-content: space-around;
    max-width: 572px;
    width: 100%;
  }

  .botones_container a {
    font-size: 13px;
    padding: 10px 12px;
    width: 100%;
    max-width: 300px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .botones_container a:hover {
    border: 1px solid #5b78aa;
  }
}
