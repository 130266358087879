@use "../../variables" as v;

.red-asterisk span {
  color: red;
}

.section_contacto_titulo {
  color: v.$color2;
  font-family: "Objectivity-Regular";
}

.section_contacto h4 {
  color: #05294f;
  font-family: "Objectivity-Regular";
  font-weight: 600;
}

.section_contacto label {
  color: v.$color2;
  font-family: "Objectivity-Regular";
  font-size: 18px !important;
  font-weight: 600;
}

select {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
  background-position: calc(100% - 20px) 12px, calc(100% - 20px) 14px, 100% 0;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.section_contacto_form_titulo {
  color: v.$color2;
  font-family: "Objectivity-Regular";
  font-size: 35px;
}

.btn.btn_section_contacto {
  color: white;
  background: #05294f;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.section_contacto_form input {
  border: 1px solid v.$color2;
}

.section_contacto_form select {
  border: 1px solid v.$color2;
}

.section_contacto_form textarea {
  border: 1px solid v.$color2;
}

.shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.shadow-external {
  -webkit-box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
  box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
}

.img-card img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.img-contacto {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.categoria_texto {
  color: v.$color2;
  font-family: Objectivity-Regular;
  padding-right: auto;
  font-weight: 600;
  font-size: 18px;
}

.fila-contacto {
  display: grid;
  grid-template-columns: 100px 1fr;
}

#contacto-mobile {
  display: none;
}

//   #contacto-desktop{
//     display: flex;
//   }

@media screen and (max-width: 576px) {
  #contacto-mobile {
    display: block;
  }

  #contacto-desktop {
    display: none;
  }

  .fila-contacto {
    grid-template-columns: 1fr;
  }
}
