@use '/src/variables' as v;

#card-cartilla{
  -webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
}

.table-cartilla > :not(:first-child) {
  border-top: 0px;
}

.table-cartilla th{
  color: #1f5f9d;
  font-weight: bold;
  // width: fit-content;
}


.table-cartilla tr{
  color: #1f5f9d;
}

.btn2-lateralr-color5{
    line-height: 20px;
    letter-spacing: 1px;
}

.btn2-lateralr-color2{
    line-height: 20px;
    letter-spacing: 1px;
}

.container-right-buttons{
    position: absolute;
    top: 50px;    
    white-space: pre-line;
}

.section_contacto_form{
  
  color: v.$color2;
  font-family: Objectivity-Regular;
  padding-right: auto  ;    
}

.container-right-buttons{
    min-height: 50px;
}

.display-linebreak {
    white-space: pre-line;

  }

  .categoria_texto{
  
    color: v.$color2;
    font-family: Objectivity-Regular;
    padding-right: auto;   
    font-weight: 600; 
    font-size: 18px;
  }

  .section_contacto_form_titulo{
    font-size: 25px;
    font-weight: 400;
  }
  

  .contenido-bottom {
    font-family: Objectivity-Regular;
    //margin-left: 90px;
    font-weight: 400;
    font-size: 16px;
    color: #004890;
    overflow: hidden;
  }

  .cartilla-buttons-desktop{
    display:block;
  }

  .cartilla-buttons-mobile{
    display:none;    
  }

  @media  (max-width:992px) {
    #card-cartilla{
      padding: 1rem 2rem 2rem 2rem !important;
    }

    .cartilla-buttons-desktop{
      display:none !important;
    } 
    .cartilla-buttons-mobile{
      display:flex !important;
      gap:12px;
      flex-wrap: nowrap;
      margin-bottom: 15px;
      justify-content: center;
    }

    .section_contacto_form_titulo{
      font-size: 25px !important;      
    }

    .btn2-lateralr-color5
    {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      font-size: 12px;
      margin-top:0px;
      line-height: 16px;
      padding: 10px 16px !important;
      width: 100%;
      height: inherit;
      text-align: center;
      flex-grow: 1;
      max-width: 190px;
      display: flex;
      justify-content: center;
    }

    .btn2-lateralr-color2
    {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      font-size: 12px;
      margin-top:0px;
      line-height: 16px;
      padding: 10px 16px !important;            
      width: 100%;
      height: inherit;
      text-align: center;
      max-width: 190px;
      display: flex;
      justify-content: center;
    }

    .container-button-search
    {
      justify-content: center !important;
    }
  }

  .contenido-bottom p
  {
    font-size: 16px;
  }

  /*.contenido {
    
  .table-cartilla > :not(:first-child) {
    border-top: 0px;
  }
  .table-cartilla th{
    color: #1f5f9d;
    font-weight: bold;
  }
      margin-left: 50px;
  .table-cartilla tr{
    color: #1f5f9d;  
  }
  }*/

  .view-map{
    font-size: 12px;
    width: max-content;    
  }