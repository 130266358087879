.img-template-2{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.template-2-bg{
    width: 100%;
    height:500px
}


.template2-section .container-right-buttons{
    position: absolute;
    top: 0;
    right: 0;
}