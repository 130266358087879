
@use '/src/variables' as v;


.container-right-buttons{
    position: absolute;
    top: 50px;
    right: 0;
}


#card-credito{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
min-height: 650px;
height: 100%;
border-color: white;

}

.categoria-texto2{

color: v.$color2;
font-family: Objectivity-Regular;
padding-right: auto;   
font-weight: 600; 
font-size: 18px;
}