@use "../../variables" as v;

.prestadoresPg{
  position: absolute;
  top: 0;
  z-index: 9999; 
  background-color: white;
  width: 98vw;
  min-height: 100vh;
  padding-left: 1vh;
}

.spinner-grow {
  width: 3rem;
  height: 3rem;
  color: v.$color2 !important;
}

.spinner-grow2 {
  width: 5rem;
  height: 5rem;
  color: v.$color2 !important;
}

.card-novedades.border-green {
  border-bottom: 8px solid v.$color6;
  -webkit-box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  // min-height: 380px; //QUITAR PARA PC
}

.card-novedades .card-title {
  margin-top: 10px;
  font-size: 28px;
  font-family: Objectivity-Regular;
  line-height: 34px;
  font-weight: 700;
  color: v.$color2;
}

.card-novedades .card-title.opc2 {
  //   font-size: 23px;
  line-height: 30px;
  font-family: Objectivity-Regular;
  color: v.$color2;
  font-weight: 600;
}

.card-novedades .card-title.opc3 {
  color: v.$color2;
}

.card-novedades .card-foto img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  // height: 170px;
}

.card-descripcion-corta.opc1 {
  font-size: 20px;
  color: v.$color2;
  font-family: Objectivity-Regular;
}

.card-descripcion-corta.opc3 {
  font-size: 22px;
  color: v.$color1;
  font-family: Objectivity-Regular;
}

.card-novedades hr.separador.opc1 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: white;
}
.card-novedades hr.separador.opc3 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: #05294f;
}

.card-novedades {
  -webkit-box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  // height: 420px; //QUITAR CUANDO ES PC
}

.title_novedades_h2 {
  font-size: 65px;
  color: #05294f;
  font-family: Objectivity-Regular;
  font-weight: 700;
}

.img-fluid {
  height: auto !important;
}
