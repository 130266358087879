@use './variables' as v;
@use './botones' as b;

// Font Family

.ff-Objectivity-Regular {
    font-family: Objectivity-Regular;
}

.ff-Objectivity-Bold {
    font-family: Objectivity-Bold;
}


/* Border Radius Utils */

.b-radius-0 {
    border-radius: 0px;
}

.b-radius-10 {
    border-radius: 10px;
}

.b-radius-20 {
    border-radius: 20px;
}

.b-radius-r-20 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.b-radius-l-20 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.b-radius-30 {
    border-radius: 30px;
}

.b-radius-40 {
    border-radius: 40px;
}

.b-radius-50 {
    border-radius: 50px;
}

//Font weight
.bold {
    font-weight: bold;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

// Separadores

hr.hr1 {
    height: 6px;
    background: v.$color1;
    opacity: 1;
    border-radius: 10px;
}

hr.hr2 {
    height: 6px;
    background: v.$color2;
    opacity: 1;
    border-radius: 10px;
}

hr.hr3 {
    height: 6px;
    background: v.$color3;
    opacity: 1;
    border-radius: 10px;
}

hr.hr4 {
    height: 6px;
    background: v.$color4;
    opacity: 1;
    border-radius: 10px;
}

hr.hr5 {
    height: 6px;
    background: v.$color5;
    opacity: 1;
    border-radius: 10px;
}

hr.hr6 {
    height: 6px;
    background: v.$color6;
    opacity: 1;
    border-radius: 10px;

}

hr.hr7 {
    height: 6px;
    background: v.$color7;
    opacity: 1;
    border-radius: 10px;
}

// Separadores delgados
hr.hr1-sm {
    height: 2px;
    background: v.$color1;
    opacity: 1;
    border-radius: 10px;
}

hr.hr2-sm {
    height: 2px;
    background: v.$color2;
    opacity: 1;
    border-radius: 10px;
}

hr.hr3-sm {
    height: 2px;
    background: v.$color3;
    opacity: 1;
    border-radius: 10px;
}

hr.hr4-sm {
    height: 2px;
    background: v.$color4;
    opacity: 1;
    border-radius: 10px;
}

hr.hr5-sm {
    height: 2px;
    background: v.$color5;
    opacity: 1;
    border-radius: 10px;
}

hr.hr6-sm {
    height: 2px;
    background: v.$color6;
    opacity: 1;
    border-radius: 10px;

}

hr.hr7-sm {
    height: 2px;
    background: v.$color7;
    opacity: 1;
    border-radius: 10px;
}

// Colores

.color-1 {
    color: v.$color1
}

.color-2 {
    color: v.$color2
}

.color-3 {
    color: v.$color3
}

.color-4 {
    color: v.$color4
}

.color-5 {
    color: v.$color5
}

.color-6 {
    color: v.$color6
}

.color-7 {
    color: v.$color7
}

.color-w {
    color: #fff
}

.color_0 {
    color: #fff;
}

.color_1 {
    color: v.$color1;   
}

.color_2 {
    color: v.$color2
}

.color_3 {
    color: v.$color3
}

.color_4 {
    color: v.$color4
}

.color_5 {
    color: v.$color5
}

.color_6 {
    color: v.$color6
}

.color_7 {
    color: v.$color7;
}

.color-w > svg {
    fill: #fff
}

.color_0 > svg {
    fill: #fff;
}

.color_1 > svg {
    fill: v.$color1;   
}

.color_2 > svg {
    fill: v.$color2
}

.color_3 > svg {
    fill: v.$color3
}

.color_4 > svg {
    fill: v.$color4
}

.color_5 > svg {
    fill: v.$color5
}

.color_6 > svg {
    fill: v.$color6
}

.color_7 > svg {
    fill: v.$color7;
}

// Botones
// Btn style1 (sin border radius)


// Btn style1 (con border radius)
.btn1-color1-r {
    @include b.fn_button_radius1($fondo: v.$color1)
}

.btn1-color2-r {
    @include b.fn_button_radius1($fondo: v.$color2)
}

.btn1-color3-r {
    @include b.fn_button_radius1($fondo: v.$color3)
}

.btn1-color4-r {
    @include b.fn_button_radius1($fondo: v.$color4)
}

.btn1-color5-r {
    @include b.fn_button_radius1($fondo: v.$color5)
}

.btn1-color6-r {
    @include b.fn_button_radius1($fondo: v.$color6)
}

.btn1-color7-r {
    @include b.fn_button_radius1($fondo: v.$color7)
}

// Btn style2 (con border radius)
.btn2-color1-r {
    @include b.fn_button_radius2($fondo: v.$color1)
}

.btn2-color2-r {
    @include b.fn_button_radius2($fondo: v.$color2)
}

.btn2-color3-r {
    @include b.fn_button_radius2($fondo: v.$color3)
}

.btn2-color4-r {
    @include b.fn_button_radius2($fondo: v.$color4)
}

.btn2-color5-r {
    @include b.fn_button_radius2($fondo: v.$color5)
}

.btn2-color6-r {
    @include b.fn_button_radius2($fondo: v.$color6)
}

.btn2-color7-r {
    @include b.fn_button_radius2($fondo: v.$color7)
}

// Btn lateral style2 (con border radius)
.btn2-lateralr-color1 {
    @include b.fn_button_lateral_radius($fondo: v.$color1)
}

.btn2-lateralr-color2 {
    @include b.fn_button_lateral_radius($fondo: v.$color2)
}

.btn2-lateralr-color3 {
    @include b.fn_button_lateral_radius($fondo: v.$color3)
}

.btn2-lateralr-color4 {
    @include b.fn_button_lateral_radius($fondo: v.$color4)
}

.btn2-lateralr-color5 {
    @include b.fn_button_lateral_radius($fondo: v.$color5)
}

.btn2-lateralr-color6 {
    @include b.fn_button_lateral_radius($fondo: v.$color6)
}