@use "../../variables" as v;

.spinner-grow {
  width: 3rem;
  height: 3rem;
  color: v.$color2 !important;
}

.spinner-grow2 {
  width: 5rem;
  height: 5rem;
  color: v.$color2 !important;
}

.card-novedades.border-green {
  border-bottom: 8px solid v.$color6;
  -webkit-box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  // min-height: 380px; //QUITAR PARA PC
}

.card-novedades .card-title {
  margin-top: 10px;
  font-size: 28px;
  font-family: Objectivity-Regular;
  line-height: 34px;
  font-weight: 700;
  color: v.$color2;
}

.card-novedades .card-title.opc2 {
  //   font-size: 23px;
  line-height: 30px;
  font-family: Objectivity-Regular;
  color: v.$color2;
  font-weight: 600;
  padding-top: 18px;
}

.card-novedades .card-title.opc3 {
  color: v.$color2;
}

.card-novedades .card-foto img {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  // height: 170px;
}

.card-descripcion-corta.opc1 {
  font-size: 20px;
  color: v.$color2;
  font-family: Objectivity-Regular;
}

.card-descripcion-corta.opc3 {
  font-size: 22px;
  color: v.$color1;
  font-family: Objectivity-Regular;
}

.card-novedades hr.separador.opc1 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: white;
}

.card-novedades hr.separador.opc3 {
  opacity: 0.6 !important;
  height: 3px !important;
  color: #05294f;
}

.card-novedades {
  -webkit-box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  box-shadow: 6px 11px 24px 3px rgba(0, 73, 144, 0.18);
  // height: 420px; //QUITAR CUANDO ES PC
}

.title_novedades_h2 {
  font-size: 65px;
  color: #05294f;
  font-family: Objectivity-Regular;
  font-weight: 700;
}

.hr-promociones {
  height: 2px;
  background: rgb(24, 90, 154);
  opacity: 1;
  border-radius: 10px;
}

#card-promociones {
  box-shadow: 0px 4px 10px 1px rgba(13, 110, 253, 0.1882352941);
  height: 100%;
  border-color: white;
  border-bottom-color: white;
  border-bottom-color: #99ca3e;
  border-bottom-width: 18px;
  border-bottom-style: solid;
}

.color1 {
  background-color: v.$color1;
  background: linear-gradient(
    180deg,
    v.$color1 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color2 {
  background-color: v.$color2;
  background: linear-gradient(
    180deg,
    v.$color2 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color3 {
  background-color: v.$color3;
  background: linear-gradient(
    180deg,
    v.$color3 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color4 {
  background-color: v.$color4;
  background: linear-gradient(
    180deg,
    v.$color4 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color5 {
  background-color: v.$color5;
  background: linear-gradient(
    180deg,
    v.$color5 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color6 {
  background-color: v.$color6;
  background: linear-gradient(
    180deg,
    v.$color6 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color7 {
  background-color: v.$color7;
  background: linear-gradient(
    180deg,
    v.$color7 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.color8 {
  background-color: v.$color8;
  background: linear-gradient(
    180deg,
    v.$color8 60%,
    rgba(255, 255, 255, 1) 100%
  );
}
