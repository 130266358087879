@use'/src/botones' as b;
@use '/src/variables' as v;

.btngestiones{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
border: 20px white;
border-radius: 20px;
display: flex;

}
#card-ver{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
height: 100%;
border-color: white;
}

.gestiones{
    font-weight: 600;
    font-family: "Objectivity-Regular";
    font-size: 15px;
    line-height: initial;
    padding: 5px 10px 5px 10px;
    color: rgb(41, 79, 144)

}

.map-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.map-container div {
    flex-grow: 1;
}

.map-container div iframe {
    flex-grow: 1;
    border-radius: 20px;
    -webkit-box-shadow: 5px 6px 8px -2px rgba(0, 0, 0, 0.13);
    box-shadow: 5px 6px 8px -2px rgba(0, 0, 0, 0.13);
    height: 293px;
    width: 373px;

}  
h2.header-accordion.accordion-header button {
    background: transparent;
    color: #05294f;
    font-weight: 700;
    font-family: "Objectivity-Regular";
    font-size: 23px;
}
.ver-hb-centros{
position: relative;
}
.container-right-buttonss{
    position: absolute;
    top: 5%;
    right: 0;
}

.card-title{
    font-family: Objectivity-Regular;
    color: #004890;
    font-weight: bold;
}



/* .header-accordion.accordion-header{
    width: 97%;
    margin: 10px;
} */



.accordion-item{
    border-radius: 15px !important;
    border: 0;
    -webkit-box-shadow: 1px 2px 12px 0px rgba(3,102,195,0.2);
    -moz-box-shadow: 1px 2px 12px 0px rgba(3,102,195,0.2);box-shadow: 1px 2px 12px 0px rgba(3,102,195,0.2);        
    margin-top: 15px;
    padding: 5px 0px;
}


.accordion-button:not(.collapsed) {
    border-bottom: 2px solid #C7E2FC;
    
}

.accordion-button {
    width: 97%;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
    transform: rotate(-180deg);
}

.accordion-button::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC) !important;
    background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0 !important
}

.accordion-button::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC) !important;
    background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0;
}

/* MV */
@media (max-width: 991.98px) {
    // .detalle-centro-medico{
    //     margin-top: 20px!important;
    // }

    .accordion-item{
       margin-bottom: 10px;
    }

    .hb-red-buttons-mobile{
        position: relative !important;
        margin-top: 25px;
        justify-items: center;

    }
   
}

iframe{
    width: 100% !important;
}

#greenAccordion{
    font-family: Objectivity-Regular;
    font-weight: lighter;
}


@media (max-width:992px){

// .btn2-lateralr-color2{
//     display: none;
// }

.card-centros-medicos{
    // height: 95%;
}
}

/* PC*/
@media (min-width: 992px) {
   
}

.accordion-body{

    font-family: Objectivity-Regular;
    color: #004890;
    font-size: 15px;
    font-weight: 600;
}
