@use "../../../../variables" as v;
@use "../../../../degradados" as d;

.section-planes {
  // @include d.fn_degradado1();
  background-size: 100% 100px;
  background-repeat: no-repeat;
}

.caption {
  bottom: 40px;
  right: 0px;
  background: rgb(83, 174, 195);
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 60%;
}

.anchorWithoutSubmenu {
  color: inherit;
  text-decoration: none;
}

.anchorWithoutSubmenu:hover {
  color: inherit !important;
  text-decoration: none;
  // color:#00A0C6;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .section-planes .container {
    width: calc(100% - 30px);
  }
}
