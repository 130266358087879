
.hb-red-buttons-mobile{
    display: none;
   }
   
   .hb-red-buttons-desktop
   {
       display: block;
   }
   

/* MV */
@media (max-width: 991.98px) {
    .container-centros{
        margin-top: 15px !important;
    }

    .hb-red-buttons-desktop
    {
        display: none;
    }

    .hb-red-buttons-mobile
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;        
        padding:10px 15px
    }

    .hb-red-centros .right-buttons{
        position: inherit !important;
    }

    .hb-red-buttons-mobile .btn2-lateralr-color2
    {
        height: 50px !important;
    }
}

/* PC*/
@media (min-width: 992px) {
    .col-lg-6{
    padding: 10px;
    }

}

.card-centros-medicos .card-title{
    font-family: Objectivity-Regular;
    color: #004890;
    font-size: 24px;
    font-weight: 900;
    padding-top: 8px;
}

.card-centros-medicos{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
min-height: 650px;
height: 100%;
border-color: white;


}

.card-centros-medicos .card-text{
    font-size: 17px;
}

.img-cover-normal {
    object-fit: cover;
    // object-position: bottom center;
    max-width: 100%;
    width: 100%;
    height: 240px;
}
.img-cover-bottom {
    object-fit: cover;
    object-position: bottom center;
    max-width: 100%;
    width: 100%;
    height: 240px;
}

.foto-centro{
    border-radius: 10px;
}

.hb-red-centros .right-buttons{
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 100;
}