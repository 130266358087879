#contenedor-whatsapp-mobile
{
  display:none;
}

#contenedor-whatsapp-desktop
{
  display: block;
}

@media (max-width: 992px) {
    #contenedor-whatsapp-mobile
    {
      display:block;
    }

    .btn-wpp-mobile {
      bottom: 20px;
    }
    
    #contenedor-whatsapp-desktop
    {
      display: none;
    }   
}