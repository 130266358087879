@use '../../../variables' as v;


.container-img{
    width: 100%;
   
}


#card-novedades{
-webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
box-shadow: 0px 4px 10px 1px #0d6efd30;
height: 100%;
border-color: white;
border-bottom-color:  #99CA3E;
border-bottom-width: thick;
border-bottom-style: solid;

}
.titulo_novedad{
    font-size: 30px;
    color: #004890;
    font-family: Objectivity-Regular;
    font-weight: 600;
}