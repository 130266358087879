.modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  max-width: 1400px;
  max-height: 960px;
  border-radius: 10px;
  cursor: pointer;
}

.zona-click {
  width: 100%;
  height: calc(100% - 15px);
  position: absolute;
  left: 0px;
  top: 15px;
}

.overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.close-modal {
  position: absolute;
  right: -25px;
  top: -25px;
  width: 60px;
}

.is-large {
  height: 90vh !important;
}

@media (max-width: 768px) {
  .img-fluid-mobile {
    min-width: 45vh;
    max-width: 100%;
    height: auto;
  }
}
