$color1: #05294F;
$color2: #004990;
$color3: #5B78AA;
$color4: #06ACCD;
$color5: #53B4C6;
$color6: #99CA3E;
$color7: #909090;
$color8: #5DCCE1;

:export {
    color1: $color1;
    color2: $color2;
    color3: $color3;
    color4: $color4;
    color5: $color5;
    color6: $color6;
    color7: $color7;
    color8:$color8;
  }