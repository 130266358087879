@use '../../degradados' as d;
@use '../../botones' as b;
@use '../../variables' as v;

.btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.btn:hover {
    cursor: pointer;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btnPointer {
    cursor: pointer;
}

/* MV */
@media (max-width: 991.98px) {
    .section-footer__titulo {
        font-size: 1.1vw;
        color: black;
    }

    .section-footer__texto {
        font-size: 1.1vw;
        text-align: justify;
        line-height: 1;
        color: black;
    }

}

/* PC*/
@media (min-width: 992px) {
    .section-footer__titulo {
        font-size: 1.2vw;
    }

    .section-footer__texto {
        font-size: 1.2vw;
        text-align: justify;
        line-height: 1;
    }

}

#footer {
    font-weight: 100 !important;
}

.section-footer {
    background-color: #ededed;
    margin-top: 20px;
    bottom: 0 b;
    position: relative;
}

.section-footer__privacidad {
    font-size: small;
    padding: 5px 0px 0px 10px;


}

.section-footer__texto {

    color: #909090;
    font-size: smaller;
    line-height: inherit;

}

.section-footer__titulo {
    margin-left: 5px;
    margin-top: 4px;
    font-weight: bold;
    color: black;


}

.footer-icon {
    height: 24.5px !important;    
}