@use "../../variables" as v;

.nav-principal {
  -webkit-box-shadow: 2px 10px 5px -8px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 2px 10px 5px -8px rgba(0, 0, 0, 0.16);
  box-shadow: 2px 10px 5px -8px rgba(0, 0, 0, 0.16);
}

#topbar {
  background: v.$color2;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 12px;
  transition: all 0.5s;
  color: white;
}

.dropdown-menu.show {
  background: v.$color4;
  //box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
  transition: 0.3s;
  display: block;
  font-family: Objectivity-Light;
}

.dropdown-menu.show a {
  color: white;
}

.dropdown-item:hover {
  color: v.$color1 !important;
  background: transparent !important;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item.dropdown {
  padding: 0px 10px;
}

a#basic-nav-dropdown {
  color: v.$color2;
  font-family: Objectivity-Light;
}

a.nav-link {
  color: v.$color2 !important;
  font-family: Objectivity-Light;
}

a#basic-nav-dropdown:hover {
  cursor: default;
  transform: scale(1.1);
  height: 100%;
  //background: v.$color4;
  display: block;
  border-radius: 5px;
  // background: v.$color4;
  //box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
  transition: 0.3s;
  display: block;
}

/*.nav-item-dropdown:hover{
    background: v.$color4;
    box-shadow: 0px 0px 30px rgb(127 137 161 / 25%);
    transition: 0.1s;
    border-radius: 5px;
    border: 1px solid #5b78aa;
    cursor:default;
    transform: scale(1.1)

}*/

///
///
.dropbtn {
  background-color: transparent;
  //color: white;
  padding: 0px 30px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  // max-width: 20vw;
  // min-width: 10vw;
  //text-align:left;
  /*min-height: 12vh;*/
  // line-height: 100px;
  color: v.$color3;
  font-family: Objectivity-Light;
  text-align: center;
  //min-width: 15vw;
  //min-width: 15vw;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: v.$color4;
  //max-width: 15vw;
  min-width: 8vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-family: Objectivity-Light;
}

.dropdown-content a {
  color: white;
  padding: 6px 20px 6px 30px;
  text-decoration: none;
  display: inline-block;
}

.dropdown-content a:hover {
  color: v.$color2;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn:hover {
  //min-width: 10vw;
}

.dropdown:hover .dropbtn {
  background: v.$color4;
  min-width: 8vw;
  // min-height: 10vh;
  text-align: center;
  // color: v.$color2 ;
  // font-family: Objectivity-Light;
  color: white;
  font-weight: bold;
}

.dropbtn:hover {
  background: v.$color4;
  /*min-width: 15vw;*/
  // min-height: 10vh;
  //text-align: center;
  // color: v.$color2 ;
  // font-family: Objectivity-Light;
  color: white;
  font-weight: bold;
}

.anchorWithoutSubmenu {
  color: inherit;
  text-decoration: none;
  /*width: 100%;*/
  //min-width: 15vw;
}

.anchorWithoutSubmenu:hover {
  color: inherit !important;
  //text-decoration: none;
  // color:#00A0C6;
  text-decoration: none;
  cursor: pointer;
  min-width: 8vw;
}

.anchorWithoutSubmenuMain {
  color: inherit !important;
  text-decoration: none;
  cursor: pointer;
  min-width: 15vw;
}

.fontMenu {
  //font-size: 8vh;
  // font-size: 22px;
  font-size: 20px;
  // text-transform: capitalize;
}

.fontHeader {
  font-size: 16px;
  font-family: Objectivity-Light;
}

.imgSize {
  width: 14vh;
  height: 12vw;
  background-image: url("../../assets/img/logo.png");
  background-size: 100% 100%;
}

.whatsapp {
  /*height: 4vh;*/
}

// @media only screen and (max-width: 720px){
//   .fontHeader{
//     font-size: 8px;
//   }
// }

// @media only screen and (max-width: 2050px){
//   .fontMenu{
//     font-size: 20px;
//     //background-color: red !important;
//   }
// }

@media only screen and (max-width: 2000px) {
  .fontMenu {
    // font-size: 18px;
    //background-color: red !important;
  }
  .fontHeader {
    font-size: 15px;
  }

  .dropbtn {
    min-width: auto;
  }
  .dropbtn:hover {
    min-width: auto;
  }

  .anchorWithoutSubmenu {
    min-width: auto;
    font-size: 14px;
  }

  .anchorWithoutSubmenu:hover {
    min-width: auto;
    font-size: 14px;
  }

  .dropdown-content {
    min-width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .fontMenu {
    font-size: 15px;
    //background-color: red !important;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background: v.$color4;
    //max-width: 15vw;
    min-width: 11vw;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-family: Objectivity-Light;
  }
  .dropdown:hover .dropbtn {
    background: v.$color4;
    min-width: 11vw;
    // min-height: 10vh;
    text-align: center;
    // color: v.$color2 ;
    // font-family: Objectivity-Light;
    color: white;
    font-weight: bold;
  }
  .anchorWithoutSubmenu:hover {
    color: inherit !important;
    //text-decoration: none;
    // color:#00A0C6;
    text-decoration: none;
    cursor: pointer;
    min-width: 11vw;
  }
  .anchorWithoutSubmenu {
    min-width: auto;
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .fontMenu {
    font-size: 16px;
  }

  .dropdown-content a {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .fontMenu {
    font-size: 14px;
  }

  .dropdown-content a {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .fontHeader {
    font-size: 12px;
  }

  .dropbtn {
    text-align: justify;
  }
}

@media only screen and (max-width: 770px) {
  .fontHeader {
    font-size: 7px;
  }
}

@media only screen and (min-width: 990px) {
  .anchorWithoutSubmenu {
    width: 100%;
  }
}
