@use "../../../variables" as v;

.container-img {
  width: 100%;
}

#card-novedades {
  -webkit-box-shadow: 4px 7px 10px 0px rgba(47, 104, 248, 0.14);
  box-shadow: 0px 4px 10px 1px #0d6efd30;
  height: 100%;
  border-color: white;
  border-bottom-color: #99ca3e;
  border-bottom-width: thick;
  border-bottom-style: solid;
}
.titulo_novedad {
  font-size: 30px;
  color: #004890;
  font-family: Objectivity-Regular;
  font-weight: 600;
}

.scrollable-table {
  overflow-x: auto;
  max-width: 100%;
}

.scrollable-table table {
  width: 100%;
  border-collapse: collapse;
  //   font-size: 0.875em;
}

.scrollable-table table th,
.scrollable-table table td {
  border: 1px solid #ccc;
  padding: 8px;
}

/* MV */
@media (max-width: 991.98px) {
  .scrollable-table table {
    font-size: 0.875em !important;
  }
}
