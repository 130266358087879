.header
{
    background-color: white;
    height: 100px;
    position:fixed;
    width: 100%;
    top:0;
    left: 0;    
    z-index: 1000;
}

.nav
{
    display: flex;
    justify-content: space-between;
    max-width: 992px;
    margin: 0 auto;
    height: 100px;
    background-color: white;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.nav-link-mobile
{
    color:white;
    text-decoration: none;
    color: #004890 !important;
    font-family: Objectivity-Regular;
}

.font-header-mobile
{
    font-size:9px;
    font-family: Objectivity-Regular;
}

.logo
{
    font-size: 20px;
    font-weight: bold;
    padding: 0 30px;
    line-height: 60px;
}

.nav-menu
{
    display: flex;
    margin-right: 30px;
    list-style: none;    
    transition: top 1s;
    flex-direction: column;
    background-color: white;
    position: fixed;            
    width: 100%;
    align-items: flex-start;
    padding: 20px 10px;
    height: max-content;
    overflow-y: auto;    
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: -1000;        
    // bottom:calc(100%);
    top:calc(-100%);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.nav-menu-buttons{
    display: flex;
    padding-left: 10px;
    width: 100%;
    padding-right: 10px !important;
    justify-content: center;
}

.nav-menu-buttons a{
    font-size:12px !important;
    padding:10px 16px !important;    
}

.nav-menu-item{
    font-size: 18px;
    margin:0 10px;    
    width:max-content;    
    line-height: 20px;
    list-style: none; 
    width: calc(100% - 20px);   
}

.nav-menu-link
{
    padding: 8px 0px;
    border-radius: 3px;
    transition: 0.5s;
    display: block;
    width: 100%;
}

.nav-menu-link:hover{
    // background-color: #034574;
}

.menu-mobile
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    // border-bottom: 1px solid #004990;
    cursor: pointer;    
    width: 100%;
    padding-right: 10px;
}

.subMenu-mobile
{
    transition: opacity 1s ease-out;
    padding-left:0px;
    margin-left:0px;    
}

.subMenu-item{
    color:#06ACCD !important;  
    padding: 5px 0px;  
}

.subMenu-li{
    // border-bottom: 1px solid #004990;
    padding: 5px 0px;
}

.separador{
    align-self: center;
    border-top: 1px solid #004990;
    margin-bottom: 0px;
    margin-top: 30px;
    min-height: 1px;
}

.nav-toggle
{
    color:white;
    background:none;
    border:none;
    font-size: 20px;
    padding: 0 30px;
    line-height: 60px;
    display: block;
}

.nav-menu_visible{
//  bottom:calc(40% - 70px);
    top:75px;       
}

.nav-whithout-shadow{
    box-shadow: inherit !important; 
}

.nav-toggle:focus:not(:focus-visible)
{
    outline: none;
}

.nav-menu li:first-child
{
    // margin-top:10px;
}