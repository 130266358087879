@use 'variables'as v;

/*
Botones Home bajo Hero
height: 90px;
font-family: 'Objectivity-Light';
font-weight: 500;
line-height: initial;

*/
@mixin fn_btn_box($fondo, $color){
    background-color: $fondo;
    padding-left: 24px;
    padding-right: 24px;
    color: $color;
    border-radius: 10px;
    font-size: 22px;
    height: 90px;
    font-family: Objectivity-Light;
    
    > a {
        color: $color;
    }
}

@mixin fn_button_radius1($fondo: #004890, $color: white, $fondoHover: white, $colorHover: v.$color1){
    font-family: Objectivity-Regular;

    border: 0;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 50px;
    transition: 0.3s;
    color: $color;
    background: $fondo;
    text-decoration: none;
    
    &:hover{
        background: $fondoHover;
        color: $colorHover;
        letter-spacing: 1px;
    padding: 12px 35px;

    }
}

@mixin fn_button_radius2($fondo: #004890, $color: white, $fondoHover: white, $colorHover: v.$color1){
    font-family: Objectivity-Regular;

    border: 0;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 4px 50px;
    margin-top: 25px;
    margin-right: 10px;
    border-radius: 50px;
    transition: 0.7s;
    color: $color;
    background: $fondo;
    text-decoration: none;
    height: 35px;
    
    &:hover{
        background: $fondoHover;
        color: $colorHover;
        border:1px solid #5b78aa;
        border-radius: 20px;
        letter-spacing: 1px;

    }
}

@mixin fn_button_radius3($fondo: #004890, $color: white, $fondoHover: white, $colorHover: v.$color1){
    font-family: Objectivity-Regular;

    font-weight: 400;
    font-size: 20px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 25px;
    margin-right: 10px;
    border-radius: 50px;
    transition:0.5;
    color: $color;
    background: $fondo;
    text-decoration: none;
    
    
    &:hover{
        background: $fondoHover;
        color: $colorHover;
        border:1px solid #0000ff;
        border-radius: 18px;
        letter-spacing: 1px;
        padding: 12px 35px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;



    }
}



//boton lateral

@mixin fn_button_lateral_radius($fondo: #004890, $color: white, $fondoHover: white, $colorHover: v.$color1){
    font-family: Objectivity-Regular;
    border: 0;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 15px;
    margin-top: 15px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    transition: 0s;
    color: $color;
    background: $fondo;
    text-decoration: none;
    width: auto;
    width: 220px;
    height: 55px;    
    box-shadow: 5px 3px 8px #888888;
    text-align: left;
    padding-left: 30px;
    display: flex;
    align-items: center;


    
    



    //word-break: break-all;
    

    
    &:hover{
        background: $fondoHover;
        color: $colorHover;
        border:1px solid #5b78aa;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border-right: 0px;
        letter-spacing: 1px;
        
    
    }
}